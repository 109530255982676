import dynamic from "next/dynamic";

import { imagePathFromImageType } from "@fwa/src/services/imageService";

import { HeaderImage } from "@fwa/src/components/styles";
import { Seasonal } from "@fwa/src/components/Seasonal";

import {
  type TeamPageType,
  type FundraisingPageType,
  type PageType,
} from "@fwa/src/types";

const EditableHeaderImageForm = dynamic(
  () => import("@fwa/src/components/EditableHeaderImageForm"),
);

type Props = {
  page: FundraisingPageType | TeamPageType;
  canEdit: boolean;
  mutatePage: (data: Partial<PageType>) => Promise<undefined | void | PageType>;
};

export const PageHeaderImage = ({ page, canEdit, mutatePage }: Props) => (
  <div style={{ aspectRatio: "3 / 1" }} data-component="page-header-image">
    {canEdit && page.uniqueId ? (
      <EditableHeaderImageForm
        pageId={page.uniqueId}
        handleEditData={(data: Partial<PageType>) =>
          mutatePage({ ...page, ...data })
        }
        currentHeaderImage={page.headerImage}
      />
    ) : (
      <Seasonal>
        <HeaderImage
          src={imagePathFromImageType(page?.headerImage)}
          alt=""
          width="1200px"
          height="400px"
        />
      </Seasonal>
    )}
  </div>
);

export default PageHeaderImage;
