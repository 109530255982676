import { Text, Box, Heading } from "@cruk/cruk-react-components";

import { formatMoneyWithCommas } from "@fwa/src/utils/formatUtils";

import { RowStretch } from "@fwa/src/components/styles";

type Props = {
  donationsTotalAmount: number;
  donationsTotalAmountFake: number;
  donationsTotalAmountFacebook: number;
};

export const TotalsSection = ({
  donationsTotalAmount,
  donationsTotalAmountFake,
  donationsTotalAmountFacebook,
}: Props) => (
  <Box>
    <Box marginBottom="xxs">
      <RowStretch>
        <Heading as="span" textSize="l" marginTop="none" marginBottom="none">
          Total raised
        </Heading>
        <Heading as="span" textSize="l" marginTop="none" marginBottom="none">
          £{formatMoneyWithCommas(donationsTotalAmount)}
        </Heading>
      </RowStretch>
    </Box>
    <Box marginBottom="xxs">
      <RowStretch>
        <Heading as="span" textSize="m" marginBottom="none" marginTop="none">
          Online
        </Heading>
        <Heading as="span" textSize="m" marginBottom="none" marginTop="none">
          £
          {formatMoneyWithCommas(
            donationsTotalAmount - donationsTotalAmountFake,
          )}
        </Heading>
      </RowStretch>
    </Box>
    <Box marginBottom="xxs">
      <RowStretch>
        <Heading as="span" textSize="m" marginBottom="none" marginTop="none">
          Offline
        </Heading>
        <Heading as="span" textSize="m" marginBottom="none" marginTop="none">
          £{formatMoneyWithCommas(donationsTotalAmountFake)}
        </Heading>
      </RowStretch>
    </Box>

    {!!donationsTotalAmountFacebook && (
      <Box marginBottom="xxs">
        <RowStretch>
          <Heading as="span" textSize="m" marginBottom="none" marginTop="none">
            Facebook
          </Heading>
          <Heading as="span" textSize="m" marginBottom="none" marginTop="none">
            £{formatMoneyWithCommas(donationsTotalAmountFacebook)}
          </Heading>
        </RowStretch>
      </Box>
    )}
    <Text marginTop="m">
      With Cancer Research UK Giving Pages more of the money raised goes towards
      beating cancer. Aside from the credit and debit card fees, every penny
      donated goes to Cancer Research UK.
    </Text>
    <Text>
      All donations made to this page will automatically be transferred to
      Cancer Research UK.
    </Text>
  </Box>
);

export default TotalsSection;
