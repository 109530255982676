import { type ThemeType, Text } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const TextResponsive = styled(Text)<{ theme: ThemeType }>`
  text-align: left;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    text-align: center;
  }
`;
export const ActivityLink = styled.span<{ theme: ThemeType }>`
  display: block;
  color: ${({ theme }) => theme.colors.textDark};
  text-decoration: none;
`;

export default TextResponsive;
