import dynamic from "next/dynamic";
import { Box, Heading, Text } from "@cruk/cruk-react-components";

import {
  type TeamPageType,
  type FundraisingPageType,
  type PageType,
} from "@fwa/src/types";

const EditablePageStoryForm = dynamic(
  () => import("@fwa/src/components/EditablePageStoryForm"),
);

export const PageStory = ({
  page,
  canEdit,
  updateAndMutatePage,
  isTeam = false,
}: {
  page: PageType;
  canEdit: boolean;
  updateAndMutatePage: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType>;
  isTeam?: boolean;
}) => {
  if (!page) return null;

  const foundStory =
    page.story ||
    (page as FundraisingPageType)?.fundraisingPageType?.story ||
    (page as TeamPageType)?.teamLeaderPage?.fundraisingPageType?.story ||
    "";

  return (
    <>
      {canEdit ? (
        <Box
          id="story"
          data-component="story"
          backgroundColor={isTeam ? "primary" : undefined}
          marginBottom={isTeam ? undefined : "xxs"}
        >
          <Heading
            h2
            textSize={isTeam ? "xxxl" : "xl"}
            marginBottom="xxs"
            textColor={isTeam ? "textOnPrimary" : "textDark"}
          >
            {`${isTeam ? "Story" : "My Story"}`}
          </Heading>
          <EditablePageStoryForm
            page={page}
            updateAndMutatePage={updateAndMutatePage}
            isTeam={isTeam}
          />
        </Box>
      ) : (
        <>
          {foundStory.length > 0 && (
            <Box
              id="story"
              data-component="story"
              backgroundColor={isTeam ? "primary" : undefined}
              marginBottom={isTeam ? undefined : "xxs"}
            >
              <Heading
                h2
                textSize={isTeam ? "xxxl" : "xl"}
                marginBottom="xxs"
                textColor={isTeam ? "textOnPrimary" : "textDark"}
              >
                {`${isTeam ? "Story" : "My Story"}`}
              </Heading>
              <Text
                textColor={isTeam ? "textOnPrimary" : "textDark"}
                paddingRight="s"
                margin="none"
                style={{
                  width: "100%",
                  display: "inline-block",
                  whiteSpace: "pre-line",
                }}
              >
                {foundStory}
              </Text>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default PageStory;
