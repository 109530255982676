import dynamic from "next/dynamic";
import styled from "styled-components";
import { Box, Heading, type ThemeType } from "@cruk/cruk-react-components";

import { type PageType } from "@fwa/src/types";

const EditablePageTitleForm = dynamic(
  () => import("@fwa/src/components/EditablePageTitleForm"),
);

const TitleBox = styled(Box)<{
  theme: ThemeType;
}>`
  padding: ${({ theme }) => theme.spacing.s};
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: inline-block;
    margin-bottom: 0;
  }
`;

export const TitleWrapper = styled.span<{
  theme: ThemeType;
}>`
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-top: ${({ theme }) => `calc(${theme.spacing.xl} * 1.5 * -1)`};
    margin-left: ${({ theme }) => theme.spacing.s};
    margin-bottom: ${({ theme }) => theme.spacing.m};
    max-width: 700px;
    width: auto;
    z-index: 1;
  }
`;

export const PageTitle = ({
  page,
  canEdit,
  isTeam = false,
  updateAndMutatePage,
}: {
  page: PageType;
  canEdit: boolean;
  isTeam?: boolean;
  updateAndMutatePage: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType>;
}) => {
  if (!page) return null;

  return (
    <TitleWrapper data-component={`${isTeam ? "team" : "page"}-title`}>
      <TitleBox backgroundColor="backgroundLight" margin="none">
        {canEdit ? (
          <EditablePageTitleForm
            page={page}
            isTeam={isTeam}
            updateAndMutatePage={updateAndMutatePage}
          />
        ) : (
          <Heading
            h1
            margin="none"
            paddingRight="s"
            style={{
              width: "100%",
              display: "block",
            }}
            wordBreak="break-word"
          >
            {page.title}
          </Heading>
        )}
      </TitleBox>
    </TitleWrapper>
  );
};

export default PageTitle;
