import { type ColorsType, type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const EditableWrapper = styled.div<{
  $fadeOnHover: boolean;
  $editButtonPosition: "top-right" | "center";
}>`
  position: relative;
  width: 100%;
  cursor: pointer;

  ${({ $editButtonPosition }) =>
    $editButtonPosition === "center"
      ? "display:flex; align-items:center;"
      : null};

  &:focus {
    outline: auto;
  }

  &:hover {
    * {
      opacity: ${({ $fadeOnHover }) => ($fadeOnHover ? "0.8" : "1")};
    }
  }
`;

export const EditIconWrapper = styled.div<{
  $editButtonColor?: string;
  $editButtonBackgroundColor?: string;
  $editButtonPosition: "top-right" | "center";
  theme: ThemeType;
}>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: ${({ $editButtonPosition }) =>
    $editButtonPosition === "top-right" ? "absolute" : "unset"};
  right: 2px;
  top: 2px;
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
  border-radius: 3px;
  background-color: ${({ $editButtonBackgroundColor, theme }) =>
    $editButtonBackgroundColor &&
    typeof theme.colors[$editButtonBackgroundColor as keyof ColorsType] !==
      "undefined"
      ? theme.colors[$editButtonBackgroundColor as keyof ColorsType]
      : $editButtonBackgroundColor || "rgba(255,255,255,0)"};
  color: ${({ $editButtonColor, theme }) =>
    $editButtonColor &&
    typeof theme.colors[$editButtonColor as keyof ColorsType] !== "undefined"
      ? theme.colors[$editButtonColor as keyof ColorsType]
      : $editButtonColor || "rgba(255,255,255,0)"};
`;
