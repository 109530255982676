import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const StyledContainer = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column-reverse;

  > * {
    width: 100%;
    padding: 0;
    margin: ${({ theme }) => theme.spacing.s} 0 0 0;
  }

  > *:last-child {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    flex-direction: row;
    > * {
      width: auto;
      padding: 0 ${({ theme }) => theme.spacing.s};
      margin: 0 ${({ theme }) => theme.spacing.s} 0 0;
    }

    > *:last-child {
      margin: 0;
    }
  }
`;

export default StyledContainer;
