import { type ReactNode, memo } from "react";
import styled, { keyframes, css } from "styled-components";
import { IconFa } from "@cruk/cruk-react-components";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";

const batGif = "/assets/images/img/bat.gif";

const getRandomInt = (min: number, max: number): number => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  return Math.floor(Math.random() * (maxInt - minInt) + minInt); // The maximum is exclusive and the minimum is inclusive
};

const now = new Date();
const isWinterSeason = now.getMonth() === 11 && now.getDate() > 18;
const isAutumnSeason = now.getMonth() === 9 && now.getDate() > 14;

const numberArray = (size: number) => Array.from(Array(size).keys());

const SeasonalWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 100;
  overflow: hidden;
`;

const SnowFlakeWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: #c9e7ff;

  ${numberArray(200).map((index) => {
    const randomX = getRandomInt(0, 100); // vw
    const randomOffset = getRandomInt(-10, 10); // vw
    const randomXEndYoyo = randomX + randomOffset / 2; // vw
    const randomScale = Math.random();
    const fallDuration = getRandomInt(10, 30); // s
    const fallDelay = getRandomInt(0, 30) * -1; // s
    const randomXVw = `${randomX}vw`;
    const randomXEndYoyoVw = `${randomXEndYoyo}vw`;
    const randomRotation = `${360 * randomScale}deg`;

    const fall = keyframes`
      to {
        transform: translate(${randomXEndYoyoVw}, 100vh) scale(${randomScale});
      }
    `;

    const animationRule = css(
      [
        "",
        ` ${fallDuration}s ${fallDelay}s linear infinite;`,
      ] as unknown as TemplateStringsArray,
      fall,
    );

    return css`
      &:nth-child(${index + 1}) {
        opacity: calc(${getRandomInt(0, 100)} * 0.01);
        transform: translate(${randomXVw}, -10px) scale(${randomScale})
          rotate(${randomRotation});
        animation: ${animationRule};
      }
    `;
  })};
`;

const brown = "#A52A2A";
const chocolate = "##D2691E";
const goldernRod = "#DAA520";

const autumnColors = [brown, chocolate, goldernRod];

const LeafWrapper = styled.div`
  pointer-events: none;
  perspective: 1000px;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: ${autumnColors[0]};
  ${numberArray(40).map((index) => {
    const randomX = getRandomInt(10, 90); // vw
    const randomYRotation = `${360 * getRandomInt(2, 10)}deg`;
    const randomOffset = getRandomInt(-10, 10); // vw
    const randomXEndYoyo = randomX + randomOffset / 2; // vw
    const randomScale = Math.random();
    const fallDuration = getRandomInt(6, 15); // s
    const fallDelay = getRandomInt(1, 10); // s
    const randomXVw = `${randomX}vw`;
    const randomXEndYoyoVw = `${randomXEndYoyo}vw`;
    const randomRotation = `${360 * randomScale}deg`;

    const fall = keyframes`
      to {
        transform: translate(${randomXEndYoyoVw}, 100vh) translateZ(${randomXEndYoyoVw}) scale(${randomScale}) rotateY(${randomYRotation});
      }
    `;

    const animationRule = css(
      [
        "",
        ` ${fallDuration}s ${fallDelay}s linear normal;`,
      ] as unknown as TemplateStringsArray,
      fall,
    );

    return css`
      &:nth-child(${index + 1}) {
        color: ${autumnColors[getRandomInt(0, autumnColors.length)]};
        transform: translate(${randomXVw}, -100px) translateZ(0)
          scale(${1 + randomScale}) rotate(${randomRotation})
          rotateY(${randomYRotation});
        animation: ${animationRule};
        transform-style: preserve-3d;
      }
    `;
  })};
`;

const flyRight = keyframes`
    to {
      transform: translate(-100vw, 0vh)
    }
  `;

const BatImage = styled.img`
  pointer-events: none;
  position: absolute;
  height: 50px;
  width: 50px;
  transform: translate(100vw, 5vh);
  animation: ${flyRight} 20s 5s forwards;
`;

const SnowFlake = () => (
  <SnowFlakeWrapper>
    <IconFa faIcon={faSnowflake} />
  </SnowFlakeWrapper>
);
const SnowFlakes = () => (
  <SeasonalWrapper>
    {numberArray(200).map((item) => (
      <SnowFlake key={`${item}-flake`} />
    ))}
  </SeasonalWrapper>
);

const Leaf = () => (
  <LeafWrapper>
    <IconFa faIcon={faCanadianMapleLeaf} />
  </LeafWrapper>
);
const Bat = () => <BatImage src={batGif} alt="" />;
const Leaves = () => (
  <SeasonalWrapper>
    {numberArray(40).map((item) => (
      <Leaf key={`${item}-flake`} />
    ))}
    <Bat />
  </SeasonalWrapper>
);

const MemoizedSnowFlakes = memo(SnowFlakes);
const MemoizedLeaves = memo(Leaves);

type Props = {
  children: ReactNode;
};

export const Seasonal = ({ children }: Props) =>
  isWinterSeason ? (
    <div style={{ position: "relative" }}>
      <MemoizedSnowFlakes />
      {children}
    </div>
  ) : isAutumnSeason ? (
    <div style={{ position: "relative" }}>
      <MemoizedLeaves />
      {children}
    </div>
  ) : (
    <div style={{ position: "relative" }}>{children}</div>
  );

export default Seasonal;
