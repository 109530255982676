import React, { type MouseEvent, type KeyboardEvent } from "react";
import { Button } from "@cruk/cruk-react-components";

import { StyledContainer } from "./styles";

type Props = {
  cancelLabel?: string;
  submitLabel?: string;
  isValid?: boolean;
  isLoading?: boolean;
  onCancel: (() => void) | null | undefined;
  onSubmit: (e: MouseEvent<HTMLElement>) => void;
  onInvalid?: () => void;
  submitButtonType: "button" | "submit";
};

export const FormActions = ({
  cancelLabel = "Cancel",
  submitLabel = "Save",
  onCancel,
  isValid = true,
  isLoading = false,
  onSubmit,
  onInvalid,
  submitButtonType,
}: Props) => {
  const doCancel = () => {
    if (isLoading) return;
    if (onCancel) {
      onCancel();
    }
  };

  const handleCancelClick = () => {
    doCancel();
  };

  const handleSubmitClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isValid) {
      onSubmit(e);
    } else if (onInvalid) onInvalid();
  };

  const handleEditClickedKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13 || e.key === "13") {
      doCancel();
    }
  };

  return (
    <StyledContainer>
      {!!onCancel && (
        <Button
          appearance="secondary"
          disabled={isLoading}
          onKeyDown={handleEditClickedKeyDown}
          onMouseDown={handleCancelClick}
          type="button"
          data-cta-type="cancel"
        >
          {cancelLabel}
        </Button>
      )}
      <Button
        type={submitButtonType}
        appearance="primary"
        disabled={isLoading}
        onClick={handleSubmitClick}
        data-cta-type="submit"
      >
        {isLoading ? "Loading..." : submitLabel}
      </Button>
    </StyledContainer>
  );
};

export default FormActions;
