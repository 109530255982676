import NextLink from "next/link";
import { Box, Link as A, IconFa } from "@cruk/cruk-react-components";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { fwsUrlPageParentMemberships } from "@fwa/src/services/fundraisingPageService";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";

import {
  ActivityItem,
  ActivityIconWrapper,
  ActivityItemWrapper,
} from "@fwa/src/components/styles";
import {
  TextResponsive,
  ActivityLink,
} from "@fwa/src/components/PageMemberOf/styles";

import {
  type FwsResponseData,
  type PageType,
  type TeamMemberType,
} from "@fwa/src/types";

type Props = {
  page: PageType;
  textColor?: string;
  fallbackData?: FwsResponseData<TeamMemberType>;
  canEdit?: boolean;
};

export const PageMemberOf = ({
  page,
  textColor,
  fallbackData,
  canEdit = false,
}: Props) => {
  const { sendTrackingEvent } = useTrackingContext();
  const { trackEvent } = useOptimizelyContext();
  const url = page
    ? `${fwsUrlPageParentMemberships({
        pageId: page.uniqueId,
        currentPage: 1,
        itemsPerPage: 3,
      })}`
    : null;
  const fallbackResponse = fallbackData;
  const { data } = useSwrGet<FwsResponseData<TeamMemberType>>(url, {
    fallbackData: fallbackResponse,
    revalidateOnMount: true,
  });
  const memberships = data?.results || [];

  return (
    <>
      {!!memberships?.length && (
        <ActivityItemWrapper>
          <ActivityItem>
            <ActivityIconWrapper $isEmpty={!memberships.length}>
              <IconFa faIcon={faPeopleGroup} size="100%" />
            </ActivityIconWrapper>

            <Box marginRight="s">
              <TextResponsive textColor={textColor}>
                {` A member of `}
                {memberships
                  ?.filter((membership: TeamMemberType) => membership.uniqueId)
                  .map((membership: TeamMemberType, index: number) => {
                    const team = membership.parentPage;
                    return (
                      <span key={membership.uniqueId}>
                        {`${index > 0 ? " and " : ""}`}
                        <NextLink
                          href={team.url.replace(/https?:\/\/[^/]*/, "")}
                          data-cta-type="link-member-team"
                        >
                          <A textColor={textColor} as="span">
                            {team.title}
                          </A>
                        </NextLink>
                      </span>
                    );
                  })}
              </TextResponsive>
            </Box>
          </ActivityItem>
        </ActivityItemWrapper>
      )}

      {!memberships?.length &&
      canEdit &&
      page.entityType === "FundraisingPage" ? (
        <ActivityItemWrapper data-component="member-of-team-search-cta">
          <NextLink
            href="/team-search"
            onClick={() => {
              trackEvent({ eventKey: "become_part_of_a_team_click" });
              sendTrackingEvent({
                event: "join_team_cta",
                cta: "fundraising_page",
              });
            }}
            data-cta-type="join-team"
          >
            <ActivityLink>
              <ActivityItem $canEdit={!memberships.length && canEdit}>
                <ActivityIconWrapper $isEmpty={!memberships.length}>
                  <IconFa faIcon={faPeopleGroup} size="100%" />
                </ActivityIconWrapper>

                <Box>
                  <A as="span">Create or join a team</A>
                </Box>
              </ActivityItem>
            </ActivityLink>
          </NextLink>
        </ActivityItemWrapper>
      ) : null}
    </>
  );
};

export default PageMemberOf;
