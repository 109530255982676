import { Box, Text, IconFa } from "@cruk/cruk-react-components";
import { Row } from "@fwa/src/components/styles";
import { faEye } from "@fortawesome/free-solid-svg-icons";

type Props = {
  textColor?: string;
};

export const OnlyYou = ({ textColor }: Props) => (
  <Box marginBottom="s">
    <Text textColor={textColor} as="span">
      <Row>
        <Box marginRight="xs">
          <IconFa faIcon={faEye} size="1.5em" />
        </Box>
        Only you can see this
      </Row>
    </Text>
  </Box>
);

export default OnlyYou;
