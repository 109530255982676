import dynamic from "next/dynamic";
import { Box, Heading } from "@cruk/cruk-react-components";

import { type PageType } from "@fwa/src/types";

const EditablePageSubtitleForm = dynamic(
  () => import("@fwa/src/components/EditablePageSubtitleForm"),
);

export const PageSubtitle = ({
  page,
  canEdit,
  updateAndMutatePage,
  isTeam = false,
}: {
  page: PageType;
  canEdit: boolean;
  updateAndMutatePage: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType>;
  isTeam?: boolean;
}) => {
  if (!page) return null;

  return (
    <>
      {canEdit ? (
        <Box
          data-component="mission-statement"
          backgroundColor={isTeam ? "primary" : undefined}
          marginVertical={isTeam ? "m" : undefined}
        >
          <EditablePageSubtitleForm
            page={page}
            updateAndMutatePage={updateAndMutatePage}
            isTeam={isTeam}
          />
        </Box>
      ) : (
        <>
          {page?.subtitle?.length > 0 && (
            <Box
              data-component="mission-statement"
              backgroundColor={isTeam ? "primary" : undefined}
              marginTop={isTeam ? "s" : undefined}
            >
              <Heading
                h2
                textSize="xxl"
                margin="none"
                paddingRight="s"
                textColor={isTeam ? "textOnPrimary" : "textDark"}
                style={{ width: "100%", display: "block" }}
              >
                {page.subtitle}
              </Heading>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default PageSubtitle;
